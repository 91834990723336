<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <v-textarea
          placeholder="Caso queira filtrar por CTMT, cole aqui os códigos, um por linha (opcional)"
          v-model="ctmtFilter"
          rows="5"
          no-resize
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <v-switch
          color="primary"
          v-model="displayOnlyRelevantEntities"
          :model-value="true"
          label="Exibir somente entidades relevantes para a carga no banco SIG-R"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <v-data-table
          id="second-step-carregar-bdgd-table"
          v-model="selectedItemLocal"
          :headers="headers"
          :items="entities"
          :search.sync="search"
          item-key="entidade"
          :single-select="false"
          show-select
          :loading="loading"
          :hide-default-header="loading"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        >
          <template
            v-slot:top
            v-if="!loading"
          >
            <span id="second-step-search">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                hide-details
                single-line
                class="mx-4 mb-3"
              />
            </span>
          </template>
          <template v-slot:[`item.entidade`]="{ item }">
            {{ item.entidade | toUpperCase }}
          </template>
          <template v-slot:[`item.registros`]="{ item }">
            {{ item.registros | parseNumberToIntegerBR }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';

export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Entidade',
        value: 'entidade'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ],
    ctmtFilter: null,
    displayOnlyRelevantEntities: false,
    relevantEntities: []
  }),
  mounted() {
    this.getEntidadesRelevantesCargaSigr();
  },
  methods: {
    getEntidadesRelevantesCargaSigr() {
      EntidadesBdgdService.getEntidadesRelevantesCargaSigr()
        .then((response) => (this.relevantEntities = response.data))
        .catch(() =>
          this.$toast.error(
            'Erro ao buscar as entidades relevantes para a carga do SIG-R.',
            '',
            {
              position: 'topRight'
            }
          )
        );
    }
  },
  computed: {
    entities() {
      return this.displayOnlyRelevantEntities
        ? this.itemsTable.filter((entity) =>
            this.relevantEntities.includes(entity.entidade.toUpperCase())
          )
        : this.itemsTable;
    },
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },
  watch: {
    selectedItem(arrNewVal) {
      this.$emit('stepReleased', arrNewVal.length);
    },
    ctmtFilter(newValue) {
      this.$emit('update:ctmtFilter', newValue);
    }
  }
};
</script>
