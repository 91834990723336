<template>
  <v-select
    v-model="server"
    name="server"
    label="Servidor GeoPerdas"
    :items="orderedServers"
    item-value="id"
    item-text="nome"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhum servidor disponível"
    :loading="loading"
    @change="serverSelected"
  />
</template>

<script>
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';

export default {
  name: 'SelectServer',
  props: {
    type: {
      type: String,
      default: 'solo'
    }
  },
  data() {
    return {
      server: null,
      servers: [],
      loading: true
    };
  },
  created() {
    this.getServers();
  },
  methods: {
    getServers() {
      ServidoresBancosDadosGeoPerdasService.getServidores()
        .then((servers) => this.setServers(servers))
        .finally(() => (this.loading = false));
    },
    setServers(servers) {
      this.servers = servers.filter((server) => server.ativo === 'Ativo');
      if (this.servers && this.servers.length) {
        this.server = this.servers[0].id;
        this.serverSelected(this.server);
      }
    },
    serverSelected(serverId) {
      const serverSelected = this.servers.find(
        (server) => server.id === serverId
      );
      this.$emit('serverSelected', serverSelected);
    }
  },
  computed: {
    orderedServers() {
      let servers = [...this.servers];
      return servers.sort((a, b) => (a.nome < b.nome ? -1 : 1));
    }
  }
};
</script>
