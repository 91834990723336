<template>
  <v-row class="mx-auto">
    <v-col
      class="pb-0 mb-10"
      cols="12"
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione o mês e versão que será carregada
      </div>
      <v-data-table
        id="first-step-carregar-bdgd-table"
        v-model="selectedItemLocal"
        :headers="headers"
        :items="items"
        item-key="key"
        :single-select="true"
        show-select
        :loading="loading"
        :hide-default-header="loading"
        :sort-by="['data_registro', 'versao']"
        :sort-desc="['data_registro', 'versao']"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        no-results-text="Nenhum registro correspondente encontrado"
      >
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }}
        </template>
        <template v-slot:[`item.registros`]="{ item }">
          {{ item.registros | parseNumberToIntegerBR }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione o servidor de banco de dados destino da carga
      </div>
      <select-server
        :type="'outlined'"
        @serverSelected="serverSelected"
      />
      <status-conexao-servidor
        v-if="selectedServerLocal"
        :servidor="selectedServerLocal"
        @conectadoServidor:changed="($event) => (conectedServer = $event)"
      />
    </v-col>
    <v-col
      cols="12"
      v-if="conectedServer"
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione a database destino da carga
      </div>
      <select-database
        :type="'outlined'"
        :onlyDatabaseWithPrefix="databasePrefix"
        :selectedServer="selectedServerLocal"
        @databaseSelected="databaseSelected"
      />
    </v-col>
  </v-row>
</template>

<script>
import SelectServer from '@/components/geo-perdas/servidores-bancos-dados/SelectServer.vue';
import SelectDatabase from '@/components/geo-perdas/servidores-bancos-dados/SelectDatabase.vue';
import StatusConexaoServidor from '@/components/geo-perdas/servidores-bancos-dados/StatusConexaoServidor.vue';

export default {
  components: {
    SelectServer,
    SelectDatabase,
    StatusConexaoServidor
  },
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    selectedServer: {
      type: Object,
      default: () => {}
    },
    selectedDatabase: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    headers: [
      {
        text: 'Mês',
        value: 'data_registro'
      },
      {
        text: 'Versão',
        value: 'versao'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ],
    conectedServer: false
  }),
  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    },
    selectedServerLocal: {
      get() {
        return this.selectedServer;
      },
      set(newValue) {
        this.$emit('update:selectedServer', newValue);
      }
    },
    selectedDatabaseLocal: {
      get() {
        return this.selectedDatabase;
      },
      set(newValue) {
        this.$emit('update:selectedDatabase', newValue);
      }
    },
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    databasePrefix() {
      return `GEO_SIGR_${this.userLoggedCompany.acronym}`;
    }
  },
  methods: {
    serverSelected(server) {
      this.selectedServerLocal = server;
      this.stepReleasedIf();
    },
    databaseSelected(database) {
      this.selectedDatabaseLocal = database;
      this.stepReleasedIf();
    },
    stepReleasedIf() {
      if (
        this.selectedItemLocal.length &&
        this.selectedServerLocal &&
        this.selectedDatabaseLocal &&
        this.conectedServer
      ) {
        this.$emit('stepReleased', 1);
      } else {
        this.$emit('stepReleased', 0);
      }
    }
  },
  watch: {
    itemsTable() {
      this.items = this.itemsTable.map((item) => {
        item.key = item.data_registro + '-' + item.versao;
        return item;
      });
    },
    selectedItem() {
      this.stepReleasedIf();
    },
    selectedServer() {
      this.stepReleasedIf();
    },
    selectedDatabase() {
      this.stepReleasedIf();
    },
    conectedServer() {
      this.stepReleasedIf();
    }
  }
};
</script>
