<template>
  <div>
    <v-progress-circular
      v-if="conectandoServidor"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-alert
        dense
        outlined
        type="success"
        color="green"
        class="py-1 px-1"
        v-if="conectadoServidor"
      >
        Conexão com o servidor realizada com sucesso.
      </v-alert>
      <v-alert
        dense
        outlined
        color="deep-orange"
        icon="mdi-alert"
        class="py-1 px-1"
        v-else
      >
        Não foi possível se conectar ao servidor.
        <div v-if="erroConexaoServidor">Erro: {{ erroConexaoServidor }}</div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';

export default {
  name: 'StatusConexaoServidor',
  props: {
    servidor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    conectandoServidor: false,
    conectadoServidor: false,
    erroConexaoServidor: null
  }),
  mounted() {
    this.testaConexao();
  },
  methods: {
    testaConexao() {
      if (!this.servidor) return;
      this.conectandoServidor = true;
      ServidoresBancosDadosGeoPerdasService.testConnection(this.servidor)
        .then(() => {
          this.$toast.success(
            'Conexão com o servidor realizada com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.conectadoServidor = true;
          this.erroConexaoServidor = null;
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao tentar se conectar ao servidor.', '', {
            position: 'topRight'
          });
          this.conectadoServidor = false;
          this.erroConexaoServidor = error.data.error;
        })
        .finally(() => (this.conectandoServidor = false));
    }
  },
  watch: {
    servidor() {
      this.testaConexao();
    },
    conectadoServidor() {
      this.$emit('conectadoServidor:changed', this.conectadoServidor);
    }
  }
};
</script>
