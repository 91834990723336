<template>
  <v-select
    v-model="database"
    name="database"
    label="Database GeoPerdas"
    :items="orderedDatabases"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhuma database encontrada"
    :loading="loading"
    @change="databaseSelected"
  />
</template>

<script>
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';

export default {
  name: 'SelectDatabase',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    selectedServer: {
      type: Object,
      default: () => {}
    },
    onlyDatabaseWithPrefix: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      database: null,
      databases: [],
      loading: true
    };
  },
  created() {
    this.getDatabases();
  },
  methods: {
    getDatabases() {
      ServidoresBancosDadosGeoPerdasService.showDatabases(this.selectedServer)
        .then((response) => this.setDatabases(response.data))
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error(
            'Erro ao testar buscar as databases disponíveis no servidor.',
            '',
            { position: 'topRight' }
          );
          this.databases = [];
        })
        .finally(() => (this.loading = false));
    },
    setDatabases(databases) {
      this.databases = databases;
      if (this.onlyDatabaseWithPrefix) {
        this.databases = databases.filter((database) =>
          database
            .toUpperCase()
            .includes(this.onlyDatabaseWithPrefix.toUpperCase())
        );
      }
      if (this.databases && this.databases.length) {
        this.database = this.databases[0];
        this.databaseSelected(this.database);
      }
    },
    databaseSelected(database) {
      this.$emit('databaseSelected', database);
    }
  },
  computed: {
    orderedDatabases() {
      let databases = [...this.databases];
      return databases.sort((a, b) => (a < b ? -1 : 1));
    }
  }
};
</script>
