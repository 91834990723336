<template>
  <v-row class="mx-auto">
    <v-col
      class="pt-1 pb-0"
      cols="12"
    >
      <v-card
        class="my-0"
        id="third-step-carregar-bdgd-confirm"
      >
        <v-toolbar
          color="primary"
          dark
          dense
          flat
        >
          <v-toolbar-title class="primary text-tabs-carregar-bdgd">
            <span>Verifique as informações e confirme</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-tabs-carregar-bdgd">
          Confirma a carga das entidades selecionadas?
        </v-card-text>
        <v-card-text class="text-tabs-carregar-bdgd">
          Nº de Linhas:
          <b style="color: red">{{ records | parseNumberToIntegerBR }}</b>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      class="pt-1 pb-0 mt-5"
      cols="12"
    >
      <v-checkbox
        class="checkbox-change-view"
        v-model="execProcedureClearDatabase"
        color="error"
        label="Apagar os dados nas tabelas antes de carregar a nova versão da BDGD?"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    records: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      execProcedureClearDatabase: false
    };
  },
  watch: {
    execProcedureClearDatabase(value) {
      this.$emit('execProcedureClearDatabase:changed', value);
    }
  }
};
</script>

<style>
.text-tabs-carregar-bdgd {
  color: #333 !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}

.text-tabs-carregar-bdgd span {
  color: #fff !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.4em !important;
  letter-spacing: 0.0073529412em !important;
}
</style>
