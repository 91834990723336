<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemFirstTab"
        :selectedServer.sync="selectedServerFirstTab"
        :selectedDatabase.sync="selectedDatabaseFirstTab"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione as entidades que serão carregadas
      </div>
      <second-tab-content
        :itemsTable="itemsTableSecondTab"
        :selectedItem.sync="selectedItemSecondTab"
        :loading.sync="loadingSecondTab"
        @update:ctmtFilter="handleCtmtFilterUpdated"
        @stepReleased="
          $emit('step-observer', { step: 1, complete: $event }),
            $emit('step-observer', { step: 2, complete: $event })
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <third-tab-content
        :records="totalRecordsThirdTab"
        @execProcedureClearDatabase:changed="
          handleExecProcedureClearDatabaseChanged
        "
      />
    </v-tab-item>
  </v-container>
</template>

<script>
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import FirstTabContent from '@/components/perdas-tecnicas/carregar-bdgd/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/perdas-tecnicas/carregar-bdgd/tabs/SecondTabContent.vue';
import ThirdTabContent from '@/components/perdas-tecnicas/carregar-bdgd/tabs/ThirdTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent,
    ThirdTabContent
  },
  created() {
    this.fillFirstTab();
  },
  data() {
    return {
      loadingFirstTab: false,
      loadingSecondTab: false,
      itemsTableFirstTab: [],
      itemsTableSecondTab: [],
      selectedItemFirstTab: [],
      selectedServerFirstTab: null,
      selectedDatabaseFirstTab: null,
      selectedItemSecondTab: [],
      execProcedureClearDatabase: false,
      totalRecordsThirdTab: 0,
      ctmtFilter: null
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    fillFirstTab() {
      this.loadingFirstTab = true;
      ExportacoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then((response) => {
          this.itemsTableFirstTab = response.data;
          const selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemFirstTab = selectedBdgdVersion;
        })
        .finally(() => (this.loadingFirstTab = false));
    },
    fillSecondTab(selectedDate, companyId, bdgdVersionId) {
      this.loadingSecondTab = true;
      ExportacoesBdgdsService.getImportedEntitiesAndRegistersByDate(
        selectedDate,
        companyId,
        bdgdVersionId
      )
        .then((response) => (this.itemsTableSecondTab = response.data))
        .catch(() =>
          this.$emit('stepReleased', { numberStep: 1, available: false })
        )
        .finally(() => (this.loadingSecondTab = false));
    },
    handleCtmtFilterUpdated(newValue) {
      this.ctmtFilter = newValue;
    },
    handleExecProcedureClearDatabaseChanged(newValue) {
      this.execProcedureClearDatabase = newValue;
    },
    exportData() {
      let arrEntidades = [];
      this.selectedItemSecondTab.forEach((item) => {
        arrEntidades.push(item.entidade);
      });
      return {
        entidades: JSON.stringify(arrEntidades),
        numero_linhas: this.totalRecordsThirdTab,
        bdgd_versao_id: this.selectedItemFirstTab[0].bdgd_versao_id,
        servidor_id: this.selectedServerFirstTab.id,
        database: this.selectedDatabaseFirstTab,
        executar_procedure_limpa_base: this.execProcedureClearDatabase,
        filtro_ctmt: this.ctmtFilter
      };
    }
  },
  watch: {
    selectedItemFirstTab(arrNewVal) {
      let selectedDate = arrNewVal.length
        ? arrNewVal[0].data_registro
        : undefined;
      let companyId = this.$store.getters.getCompanyId;
      let bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : undefined;
      if (selectedDate && companyId && bdgdVersionId) {
        this.fillSecondTab(selectedDate, companyId, bdgdVersionId);
      }
    },
    selectedItemSecondTab(arrNewVal) {
      let totalRecords = 0;
      if (arrNewVal.length) {
        totalRecords = arrNewVal
          .map((item) => item.registros)
          .reduce((prev, next) => prev + next);
      }
      this.totalRecordsThirdTab = totalRecords;
    }
  }
};
</script>
